import { LinkSolid } from "@graywolfai/react-heroicons";
import React from "react";
import Heading from "./Heading";

export default function ProjectCard(props) {
    const {
        imgUrl,
        imgAlt,
        linkUrl,
        linkTitle,
        children,
        className,
        ...propsRest
    } = props;

    const imgUrlTrue = process.env.PUBLIC_URL + imgUrl;

    return (
        <div
            className={`flex flex-col relative z-10 mb-6 lg:mb-0 lg:mx-5 ${className}`}
            {...propsRest}
        >
            <div
                className="absolute bg-green-700 w-full h-full top-1 left-1"
                style={{ zIndex: -1 }}
            ></div>
            <a
                href={linkUrl}
                rel="noreferrer"
                target="_blank"
                className="group block h-72 lg:h-52 flex-grow-0 relative transition-all"
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${imgUrlTrue})`,
                }}
                alt={imgAlt}
            >
                <div
                    className="absolute w-full h-full top-0 left-0 pointer-events-none
                bg-gradient-to-br from-transparent to-green-600 bg-opacity-50
                transition-opacity opacity-0 group-hover:opacity-100"
                ></div>
            </a>
            <div className="p-4 border border-green-800 bg-white flex-grow flex flex-col">
                <Heading
                    level={4}
                    className="mb-2 flex-grow-0 transition-colors hover:bg-green-700 hover:text-white"
                >
                    <a
                        href={linkUrl}
                        rel="noreferrer"
                        target="_blank"
                        className="block p-1"
                    >
                        <LinkSolid className="h-6 mr-2 -mt-1.5 inline" />
                        {linkTitle}
                    </a>
                </Heading>
                {children}
            </div>
        </div>
    );
}
