import React from "react";

/**
 * Inserts a heading element.
 * @param props.level - 1 to 6, defines h1 to h6 accordingly
 * @param {boolean} props.center - defines if text should be centered.
 */
export default function Heading(props) {
    if(props.center && typeof props.center !== 'boolean') throw new Error("The 'center' prop must be a boolean.");

    const {level, center, style, children, className, ...alteredProps} = props;

    const headingLevel = props.level ?? 3; //default h3

    const CustomHeading = `h${headingLevel}`;
    const weightClass = headingLevel <= 3 ? "font-extrabold" : "font-bold";
    const sizeClass = headingLevel < 6 ? `text-${6 - headingLevel}xl` : "text-xl";
    const centerClass = center ? "text-center" : null;

    return (
        <CustomHeading
            className={`${weightClass} ${sizeClass} ${centerClass} ${className}`}
            style={{fontFamily: "'Archivo Black', sans-serif", ...style}}
            {...alteredProps}
        >
            {props.children}
        </CustomHeading>
    );
}
