import {
    DocumentDownloadOutline,
    MailOutline,
} from "@graywolfai/react-heroicons";
import Aos from "aos";
import React, { useEffect, useRef } from "react";
import { Link, Element, scrollSpy } from "react-scroll";
import Button from "./reusable/Button";
import Heading from "./reusable/Heading";
import ProjectCard from "./reusable/ProjectCard";
import TechStack from "./TechStack";
import Timeline from "./Timeline";

export default function App() {
    const subjectRef = useRef("");
    const bodyRef = useRef("");

    useEffect(() => {
        Aos.init({
            delay: 100,
            duration: 800,
            mirror: true,
        });
        scrollSpy.update();
    }, []);

    function submitMail(e) {
        e.preventDefault();

        window.location.href = `mailto:karlomiguelangelo@gmail.com?subject=${
            subjectRef.current.value
        }&body=${encodeURI(bodyRef.current.value)}`;
    }

    return (
        <div
            className="flex flex-col items-center justify-center 
            w-full h-full
        bg-yellow-100 bg-opacity-10 
        overflow-hidden relative"
            id="main"
        >
            <div
                className="fixed top-0 left-0 w-full z-50 bg-white p-4
            flex flex-row font-mono text-sm lg:text-base"
                id="nav"
            >
                <img
                    src={process.env.PUBLIC_URL + "/assets/logo-black.svg"}
                    alt="Karlo Angelo logo"
                    className="h-6 lg:h-8 mr-6"
                />
                <Link
                    className="mx-2 lg:mx-4 cursor-pointer transition-colors hover:text-red-500"
                    activeClass="underline text-red-500"
                    to="bio"
                    spy
                    smooth
                    duration={400}
                    offset={-120}
                >
                    bio
                </Link>
                <Link
                    className="mx-2 lg:mx-4 cursor-pointer transition-colors hover:text-red-500"
                    activeClass="underline text-red-500"
                    to="tech"
                    spy
                    smooth
                    duration={400}
                    offset={-200}
                >
                    info
                </Link>
                <Link
                    className="mx-2 lg:mx-4 cursor-pointer transition-colors hover:text-red-500"
                    activeClass="underline text-red-500"
                    to="proj"
                    spy
                    smooth
                    duration={400}
                    offset={-160}
                >
                    projects
                </Link>
                <Link
                    className="mx-2 lg:mx-4 cursor-pointer transition-colors hover:text-red-500"
                    activeClass="underline text-red-500"
                    to="contact"
                    spy
                    smooth
                    duration={400}
                    offset={-100}
                >
                    contact
                </Link>
            </div>
            <Element className="relative w-full py-12 md:py-32" name="bio">
                <div
                    className="absolute w-full -top-1/4
                pattern-dots-lg bg-pink-100 text-green-800 text-opacity-40
                z-0 transform -skew-y-6"
                    style={{ height: "125%" }}
                ></div>
                <div
                    className="relative 
                    flex flex-col lg:flex-row items-center justify-items-center 
                    w-5/6 lg:w-4/6 mx-auto mt-6 z-10"
                    id="bio"
                >
                    <div className="w-1/2 lg:w-2/5 p-2 lg:p-10 relative mb-12 lg:mb-0">
                        <img
                            src={process.env.PUBLIC_URL + "/assets/mymemoji.png"}
                            alt="Karlo"
                            className="rounded-full
                            relative z-10"
                            data-aos="fade-left"
                        />
                        <div
                            className="none rounded-full z-0 
                        w-4/5 h-4/5  lg:w-5/6 lg:h-5/6
                        top-8 -left-4 lg:top-16 lg:-left-8
                        pattern-grid-sm bg-pink-100 text-red-600"
                            style={{
                                backgroundSize: "11px 11px",
                                imageRendering: "crisp-edges",
                            }}
                            data-aos="fade-right"
                        ></div>
                    </div>
                    <div className="w-full lg:w-3/5 flex flex-col align-middle justify-center">
                        <div
                            className="relative w-2/3 mx-auto p-6 mb-12 bg-white 
                        text-justify lg:text-right text-xl xl:text-4xl font-mono"
                            data-aos="flip-up"
                        >
                            <div className="absolute -top-8 pb-1 left-0 text-base lg:text-lg bg-pink-100">
                                &lt;intro&gt;
                            </div>
                            <div className="absolute -bottom-8 pt-1 right-0 text-base lg:text-lg bg-pink-100">
                                &lt;/intro&gt;
                            </div>
                            hi, i'm
                            <span className="text-green-700 font-semibold">
                                {" "}
                                karlo{" "}
                            </span>
                            and i'm a
                            <span className="text-green-700 font-semibold">
                                {" "}
                                web developer{" "}
                            </span>
                            based in
                            <span className="text-green-700 font-semibold">
                                {" "}
                                manila
                            </span>
                            .
                        </div>

                        <div
                            className="px-0 xl:px-12 
                        flex flex-col lg:flex-row 
                        justify-between lg:justify-around items-center 
                        text-sm xl:text-base"
                            data-aos="fade-right"
                        >
                            <Button
                                href={
                                    process.env.PUBLIC_URL +
                                    "/assets/resume.pdf"
                                }
                                className="bg-green-700 text-white mb-3 relative"
                            >
                                <div className="absolute h-4 w-4 rounded-full -top-1 -right-1 bg-pink-500"></div>
                                <div className="absolute h-4 w-4 rounded-full -top-1 -right-1 bg-red-300 animate-ping"></div>
                                <DocumentDownloadOutline className="h-6 inline mr-2 relative -top-0.5" />
                                Résumé / CV
                            </Button>
                            <Button
                                href="https://github.com/karleaux"
                                className="bg-black text-white mb-3"
                            >
                                <i className="relative devicon-github-original text-lg mr-2 -bottom-0.5"></i>
                                Github
                            </Button>
                            <Button
                                href="https://www.linkedin.com/in/karloangelo/"
                                className="bg-blue-800 text-white mb-3"
                            >
                                <i className="relative devicon-linkedin-plain text-lg mr-2 -bottom-0.5"></i>
                                LinkedIn
                            </Button>
                        </div>
                    </div>
                </div>
            </Element>
            <Element className="flex flex-wrap w-5/6 mt-48 mb-36" name="tech">
                <div className="w-full lg:w-7/12 px-0 lg:pl-12 lg:pr-20 ">
                    <TechStack />
                </div>
                <div className="w-full lg:w-5/12 pt-12 lg:pt-0 relative lg:-top-20">
                    <Timeline />
                </div>
            </Element>
            <Element
                className="w-full py-16 lg:pt-32 lg:pb-44 relative z-10"
                name="proj"
            >
                <div
                    className="absolute w-full h-full top-0 left-0
                pattern-diagonal-lines-md text-green-700 text-opacity-50 
                bg-pink-50
                border-t border-b border-green-700
                transform -skew-y-6 z-0"
                ></div>
                <Heading
                    level={2}
                    center
                    className="relative z-10 text-green-900 mb-6"
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-delay="0"
                >
                    Career Projects
                </Heading>
                <div
                    className="w-3/4 lg:w-5/6 mx-auto grid grid-cols-1 lg:grid-cols-3"
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-delay="400"
                >
                    <ProjectCard
                        imgUrl="/assets/galileo-screen.jpg"
                        alt="Screenshot of Galileo.ph site"
                        linkTitle="Galileo.ph"
                        linkUrl="https://galileo.ph/"
                    >
                        <p className="font-semibold flex-grow-0">
                            Galileo Software Services Inc. | February - March
                            2021
                        </p>
                        <p className="flex-grow flex flex-col justify-center">
                            The Galileo.ph project's goal was to revamp the
                            company's current website and add functionality for
                            job/internship applications natively through the
                            site, instead of the previous method of using Google
                            Forms.
                            <span className="block h-4"></span>
                            As the developer, I was tasked with creating the
                            site via WordPress, implementing the native
                            application form functionality, and transferring the
                            design team's mockups into code for the live
                            website.
                        </p>
                    </ProjectCard>

                    <ProjectCard
                        imgUrl="/assets/onlinepl-screen.jpg"
                        alt="Screenshot of ONLINE Partylist site"
                        linkTitle="ONLINE Partylist"
                        linkUrl="https://onlinepartylist.ph/"
                    >
                        <p className="font-semibold flex-grow-0">
                            Galileo Software Services Inc. | March 2021
                        </p>
                        <p className="flex-grow flex flex-col justify-center">
                            The ONLINE Partylist site was created to promote the
                            company CEO Jun Lozada's advocacy group, whose goals
                            are concerned with the provision of free and
                            reliable internet for both private and public
                            schools in the Philippines.
                            <span className="block h-4"></span>
                            My primary tasks in this project were to implement
                            and extend the design team's vision for this site
                            into front-end code.
                        </p>
                    </ProjectCard>

                    <ProjectCard
                        imgUrl="/assets/abizo-screen.jpg"
                        alt="Placeholder for the Abizo Web Client site graphic"
                        linkTitle="Abizo Pro - Web Client Project"
                        linkUrl="https://abizo.ph/"
                    >
                        <p className="font-semibold flex-grow-0">
                            Galileo Software Services Inc. | March - April 2021
                        </p>
                        <p className="flex-grow flex flex-col justify-center">
                            The Abizo Web Client project was started to provide
                            a web portal for users of the Abizo mobile app.
                            <span className="block h-4"></span>
                            Though my internship at Galileo was finished before
                            I could complete this, I was able to complete the
                            foundational/structural code & the authorization
                            module (Firebase JWT-based) for this project before
                            handoff.
                        </p>
                    </ProjectCard>
                </div>

                <Heading
                    level={2}
                    center
                    className="relative z-10 text-green-900 mt-20 mb-6"
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="200"
                >
                    Academic Projects
                </Heading>
                <div
                    className="w-3/4 lg:w-5/6 mx-auto grid grid-cols-1 lg:grid-cols-2"
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="400"
                >
                    <ProjectCard
                        imgUrl="/assets/needl-screen.jpg"
                        alt="Screenshot of NeedL site"
                        linkTitle="NeedL | Capstone Project"
                        linkUrl="https://isproj2b.benilde.edu.ph/NeedL"
                    >
                        <p className="font-semibold flex-grow-0">
                            De La Salle-College of Saint Benilde | August 2020 -
                            October 2020
                        </p>
                        <p className="flex-grow flex flex-col justify-center">
                            NeedL was the Capstone project for Karlo's thesis
                            group in Benilde. It is a home-based diagnostic test
                            service similar in concept to Grab or PostMates
                            where customers can avail of clinical testing
                            services from the safety and convenience of their
                            homes.
                            <span className="block h-4"></span>
                            The NeedL Capstone project won Best Capstone for the
                            semester, and was lauded by students and faculty
                            alike.
                        </p>
                    </ProjectCard>

                    <ProjectCard
                        imgUrl="/assets/bookit-screen.jpg"
                        alt="Screenshot of BookIt site"
                        linkTitle="BookIt"
                        linkUrl="https://bookit-csb.herokuapp.com/"
                    >
                        <p className="font-semibold flex-grow-0">
                            De La Salle-College of Saint Benilde | August 2020 -
                            December 2020
                        </p>
                        <p className="flex-grow flex flex-col justify-center">
                            BookIt was a web app developed with Laravel as the
                            summative group project to complete a web
                            development course in Benilde. It was a freelancing
                            site similar to Fiverr tailored to the creatives &
                            humanities fields (e.g. artwork, proofreading, etc.)
                        </p>
                    </ProjectCard>
                </div>
            </Element>
            <Element
                className="w-full pt-48 pb-60 relative z-0 bg-gray-900"
                name="contact"
            >
                <div
                    className="absolute bg-gray-900 w-full h-full top-0 left-0 transform -skew-y-6"
                    style={{ zIndex: -1 }}
                ></div>
                <div
                    className="absolute w-48 h-48 rounded-full
                left-12 xl:left-32 top-1/4
                text-red-500 pattern-dots-xl"
                    data-aos="zoom-in"
                    data-aos-delay="600"
                    data-aos-duration="200"
                ></div>
                <div
                    className="absolute w-48 h-48 rounded-full
                right-12 xl:right-32 top-3/4
                text-red-500 pattern-cross-dots-md "
                    data-aos="zoom-in"
                    data-aos-delay="600"
                    data-aos-duration="200"
                ></div>
                <Heading
                    level={2}
                    center
                    className="font-mono text-white"
                    data-aos="fade-down"
                >
                    Connect
                </Heading>
                <div
                    className="w-3/5 xl:w-1/3 mx-auto mt-8 relative -left-3"
                    data-aos="zoom-in-up"
                >
                    <div
                        className="z-0 absolute w-full h-full left-6 top-6 
                    pattern-dots-sm text-red-500"
                    ></div>
                    <form
                        onSubmit={submitMail}
                        className="w-full relative z-40 
                    p-6 md:p-16 bg-gray-900 border border-red-500
                    flex flex-col items-center"
                    >
                        <input
                            ref={subjectRef}
                            name="subject"
                            placeholder="Subject"
                            type="text"
                            className="w-full block my-6 p-4 
                            bg-transparent text-white border border-white
                            transition-colors focus:border-red-500"
                        />
                        <textarea
                            ref={bodyRef}
                            placeholder="Message"
                            className="w-full block my-6 p-4
                            bg-transparent text-white border border-white
                            transition-colors focus:border-red-500"
                        ></textarea>
                        <button
                            className="bg-white text-gray-900 font-semibold 
                            px-4 py-2 rounded-full
                            transition-colors hover:bg-red-500 hover:text-white"
                            type="submit"
                        >
                            <MailOutline className="inline-block h-6 mr-2" />
                            Send message
                        </button>
                    </form>
                </div>
            </Element>
        </div>
    );
}
