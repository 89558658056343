import React from "react";
import Heading from "./reusable/Heading";

export default function TechStack(props) {
    return (
        <div className={`${props.className}`}>
            <Heading level={3} center className="mb-6" data-aos="fade-up">
                Tech Stack
            </Heading>

            <Heading level={4} className="relative text-pink-600" data-aos="fade-up" data-aos-delay="200">
                <div
                    className="absolute 
                    w-1/2 left-1/2 md:w-3/4 md:left-1/4 lg:w-2/3 lg:left-1/3 xl:w-3/4 xl:left-1/4
                    h-1 top-1/2 
                    bg-gradient-to-r from-pink-400 to-green-300"
                ></div>
                Front-end
            </Heading>
            <div className="grid grid-cols-2 lg:grid-cols-3 place-content-start gap-y-6 mt-6 mb-12"
             data-aos="fade-up" data-aos-delay="200">
                <div className="flex items-center">
                    <i className="devicon-html5-plain-wordmark text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        HTML5
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-css3-plain-wordmark text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        CSS3
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-javascript-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        JavaScript
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-bootstrap-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        Bootstrap
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-jquery-plain-wordmark text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        jQuery
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-sass-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        SASS
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-react-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        React
                    </span>
                </div>
            </div>
            <Heading level={4} className="relative text-green-600 mt-8"
            data-aos="fade-up" data-aos-delay="400">
                <div
                    className="absolute 
                    w-1/2 left-1/2 md:w-3/4 md:left-1/4 lg:w-2/3 lg:left-1/3 xl:w-3/4 xl:left-1/4
                    h-1 top-1/2 
                    bg-gradient-to-r from-green-500 to-red-200"
                ></div>
                Back-end
            </Heading>
            <div className="grid grid-cols-2 lg:grid-cols-3 place-content-start gap-y-6 mt-6 mb-12"
            data-aos="fade-up" data-aos-delay="500">
                <div className="flex items-center">
                    <i className="devicon-php-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        PHP
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-laravel-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        Laravel
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-firebase-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        Firebase
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-csharp-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        C-Sharp
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-mysql-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        MySQL
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-microsoftsqlserver-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        Microsoft SQL
                    </span>
                </div>
                <div className="flex items-center">
                    <i className="devicon-java-plain text-3xl"></i>
                    <span className="w-3/4 text-lg font-semibold pl-3 md:pl-4 lg:pl-6">
                        Java
                    </span>
                </div>
            </div>
        </div>
    );
}
