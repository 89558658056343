import { ChevronRightSolid } from "@graywolfai/react-heroicons";
import React from "react";
import Loader from "react-loader-spinner";
import Heading from "./reusable/Heading";

export default function Timeline(props) {
    return (
        <div className={`lg:pl-20 ${props.className}`}>
            <Heading level={3} center className="mb-6" 
            data-aos="fade-down" data-aos-delay="300">
                Experience
            </Heading>

            <div className="grid grid-cols-11 place-items-stretch"
            data-aos="fade-right" data-aos-delay="200">
                <div className="col-span-5"></div>
                <div className="col-span-1 relative flex justify-center pt-2">
                    <div className="absolute h-full w-1 left-1/2 top-0 -ml-0.5 bg-green-700 z-0"></div>
                    <span className="inline-block w-5 h-5 bg-green-700 z-10 transform rotate-45"></span>
                </div>
                <div className="col-span-5 pt-4 pb-10 relative">
                    <div className="z-0 absolute w-full h-1 bg-green-700 -left-6 top-4"></div>
                    <h5 className="font-black uppercase tracking-wide bg-green-700 text-white z-10 relative">
                        <a
                            href="https://galileo.ph/"
                            target="_blank"
                            rel="noreferrer"
                            className="block px-3 py-1
                            transform transition-transform translate-x-0 hover:translate-x-2"
                        >
                            Galileo Software Services, Inc.
                        </a>
                    </h5>
                    <p className="text-sm italic">
                        <strong>Web Developer Intern</strong> | February - April
                        2021
                    </p>
                    <ul className="list-none mt-1.5 font-mono text-sm">
                        <li>
                            <ChevronRightSolid className="inline h-4" />
                            Galileo.ph Project - WordPress backend development
                        </li>
                        <li>
                            <ChevronRightSolid className="inline h-4" />
                            ONLINE Partylist Project - Front-end design to code
                        </li>
                        <li>
                            <ChevronRightSolid className="inline h-4" />
                            Abizo Web Client Project - Foundational code
                        </li>
                    </ul>
                </div>
            </div>
            <div className="grid grid-cols-11 place-items-stretch"
            data-aos="fade-left" data-aos-delay="200">
                <div className="col-span-5 text-right relative pt-2">
                    <div className="z-0 absolute w-full h-1 bg-green-700 -right-6 top-2"></div>
                    <h5 className="font-bold uppercase tracking-wide bg-green-700 text-white z-10 relative">
                        <a
                            href="https://www.benilde.edu.ph/"
                            target="_blank"
                            rel="noreferrer"
                            className="block px-3 py-1
                            transform transition-transform translate-x-0 hover:-translate-x-2"
                        >
                            De La Salle-College of Saint Benilde
                        </a>
                    </h5>
                    <p className="text-sm italic">
                        <strong>BS Information Systems Graduate</strong> |
                        August 2017 - July 2021
                    </p>
                    <p className="list-none mt-1.5 font-mono text-sm">
                        The Information Systems course in Benilde equipped me
                        with industry knowledge, programming & logic skills and
                        business acumen for a burgeoning web development career.
                    </p>
                </div>
                <div className="col-span-1 relative flex justify-center">
                    <div className="absolute h-full w-1 left-1/2 top-0 -ml-0.5 bg-green-700 z-0"></div>
                    <span className="inline-block w-5 h-5 bg-green-700 z-10 transform rotate-45"></span>
                </div>
                <div className="col-span-5"></div>
            </div>
            <div className="grid grid-cols-11 place-items-stretch"
            data-aos="fade-right" data-aos-delay="200">
                <div className="col-span-5"></div>
                <div className="col-span-1 relative flex justify-center pt-2">
                    <div className="absolute h-full w-1 left-1/2 top-0 -ml-0.5 bg-green-700 z-0"></div>
                    <span className="inline-block w-5 h-5 bg-green-700 z-10 transform rotate-45"></span>
                </div>
                <div className="col-span-5 pt-4 pb-10 relative">
                    <div className="z-0 absolute w-full h-1 bg-green-700 -left-6 top-4"></div>
                    <h5 className="font-black uppercase tracking-wide bg-green-700 text-white z-10 relative">
                        <div className='absolute bg-red-400 h-4 w-4 rounded-full -top-1.5 -right-1.5'></div>
                        <div className='absolute bg-red-500 h-4 w-4 rounded-full -top-1.5 -right-1.5 animate-ping'></div>
                        <div
                            className="block px-3 py-1"
                        >  
                            <Loader type='Oval' color='#fff' height='1.25em' width='1.25em'
                            className='inline-block mr-3 align-middle pb-0.5'/>
                            <span className="animate-pulse">
                                in progress...
                            </span>
                        </div>
                    </h5>
                </div>
            </div>
        </div>
    );
}
