import React from "react";

/**
 * Inserts a Button element.
 *
 */
export default function Button(props) {
    const classes = `inline-block rounded-full py-2 px-4 font-bold ${props.className}`;

    return (
        <a
            href={props.href}
            target="_blank"
            rel="noreferrer"
            className={classes}
        >
            {props.children}
        </a>
    );
}
